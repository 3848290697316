:root {
    --primary-color: #f26649;
    --bg-primary-30: #f2654930;
    --bg-primary-50: #f2654950;
    --bg-primary-70: #f2654970;
    --primary-hover-color: #f26549e6;
    --secondary-color: #70c2b3;
    --state-focus-shadow: 0 0 0 3px rgb(from #f26649 r g b/25%);
    --color-white: #f5f5f5;
    --color-transparent: #ffffff00;
}
.custom-button{
    color: var(--color-white);
    background-color: var(--primary-color);
}
.custom-button:hover, .custom-button:focus{
    color: var(--color-white);
    background-color: var(--primary-hover-color);
}
.custom-button-outline{
    border: 1px solid var(--primary-color);
    background-color: var(--color-transparent);
    color: var(--primary-color);
}
.custom-button-outline:hover, .custom-button-outline:focus{
    border: 0 !important;
    background-color: var(--primary-hover-color);
    color: var(--color-white);
}
.navbar{
    margin: 20px;
    padding: 10px 0;
    border-radius: 5px;
}

.panel-signup{
    width: 420px;
    margin: auto;
}
.panel-signup .title {
    text-align: center;
    font-weight: bold;
    font-size: large;
}
.panel-100 {
    height: calc(100vh - 250px); 
    margin-bottom: 10px;
}
.tag{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: #E9D7FE;
    color: #9E77ED;
}
.input_bottom{
    border-top: 0;
    border-left: 0;
    border-right: 0;
}
.w-mob{
    width: 480px;
}
.m-20{
    margin: 20px;
}
.mx-20{
    margin: 0 20px;
}
.mt-20{
    margin-top: 20px;
}
.mb-5{
    margin-bottom: 5px;
}
.mb-10{
    margin-bottom: 10px;
}
.mb-20{
    margin-bottom: 20px;
}


@media screen and (min-width: 480px){
    
}


